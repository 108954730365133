import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core'

import * as fromUserStore from '@app/users/store'

import { Store } from '@ngrx/store'
import * as fromSidebarStore from '@app/sidebar/store'
import { Observable } from 'rxjs'
import { User } from '@app/shared/models'
import { map } from 'rxjs/operators'
import { FOLLOW_UP_RESOURCE } from '@app/shared/models'

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SideBarMenuComponent implements OnInit {
  @Input()
  set isActive(val) {
    this.options.isActiveSidebar = val || false
    if (!this.options.isActiveSidebar) {
      this.store.dispatch(new fromSidebarStore.GetScheduleEvents({ short: true }))
    }
  }
  @Output()
  onNavMobileClose = new EventEmitter<void>()
  @Output()
  onActiveMenu = new EventEmitter<boolean>()
  @Input()
  type: string
  @Input()
  appVer
  @Input()
  options: {
    mobile?: boolean,
    isActiveSidebar?: boolean
  } = {
    isActiveSidebar: false
  }

  activeMenuTab = signal('')
  notificationsUnReadCount$: Observable<number>
  tasksUnReadCount$: Observable<number>
  currentUser$: Observable<User>
  users
  userIsCustomer = signal(false);
  unreadThreadsCount$: Observable<number>
  expiredDatesCount$: Observable<number>
  canDisplayExpirationList$: Observable<any>
  users$: Observable<User[]>

  FOLLOW_UP_RESOURCE = FOLLOW_UP_RESOURCE

  fromSidebarStore = fromSidebarStore

  constructor(
    private store: Store<fromSidebarStore.State>,
  ) { }

  ngOnInit() {
    this.store.dispatch(new fromSidebarStore.GetScheduleEventsLive({}))

    this.notificationsUnReadCount$ = this.store.select(
      fromSidebarStore.selectUnreadedScheduleEventNotificationsCount
    )
    this.users$ = this.store.select(fromSidebarStore.selectAllUsers)
    this.unreadThreadsCount$ = this.store.select(fromSidebarStore.selectThreadsUnreadCount)
    this.expiredDatesCount$ = this.store.select(fromSidebarStore.selectExpiredExpirationsCount)
    this.currentUser$ = this.store.select(fromUserStore.getUser).pipe(
      map(u => {
        if (u?.type === "CUSTOMER") { this.userIsCustomer.set(true) }
        return u
      })
    )
    this.canDisplayExpirationList$ = this.store.select(fromUserStore.getPermissions).pipe(map(p => p?.expirations_list))

    this.tasksUnReadCount$ = this.store.select(fromSidebarStore.selectAllTasksWithUnread)
      .pipe(
        map(tasks => tasks.filter(t => !t._read).length)
      )
    this.options?.mobile ? this.updateMenuActive('Notifications') : null
  }

  updateMenuActive = (tab:'Notifications' | 'My Tasks' | 'Messages' | 'Users' | 'Expirations') => {
    if (this.activeMenuTab() === tab && !this.options.mobile) {
      this.onActiveMenu.emit(false)
      this.activeMenuTab.set('')
    } else {
      this.activeMenuTab.set(tab)
      if (this.activeMenuTab() === 'Notifications') {
        this.store.dispatch(new fromSidebarStore.GetScheduleEvents())
      }
      else {
        this.store.dispatch(new fromSidebarStore.GetScheduleEvents({ short: true }))
      }
      this.onActiveMenu.emit(true)
    }
  }
}
