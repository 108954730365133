import {
    Component,
    OnInit,
    OnDestroy,
  } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import {  genMockClaim } from '@app/claims/models/claim.model';

import * as fromStore from '@app/claims/store';
import * as fromCoreStore from '@app/core/store';
import { CloseModal } from '@app/modals/store/actions/layout.actions';

@Component({
    selector: 'app-new-claim',
    templateUrl: './new-claim.component.html',
    styleUrls: ['new-claim.component.css'],
    standalone: false
})

export class NewClaimComponent implements OnInit, OnDestroy {

    destroyed$ = new Subject<boolean>();
    types = [
        'AUTO',
        'TRUCK',
        'OTHER'
    ];

    claimType = 'AUTO';
    claimOrder;

    claimantContacts = [];
    claimantAccountId;
    contacts = [];

    constructor(
      private store: Store<fromStore.State>,
      private coreStore: Store<fromCoreStore.State>
    ) {}

    ngOnInit() {

    }

    addClaimantContact = (type, contact) => {
      if (type === 'accountId') {
        this.claimantAccountId = contact._id;
      }
      this.contacts.push(contact);
    };

    getContactById(id: string) {
      if (this.contacts) {
        return this.contacts.find(c => c._id === id);
      }
    }

    removeClaimant() {
      this.claimantAccountId = '';
      this.claimantContacts = [];
      this.contacts = [];
    }

    setType(type) {
      this.removeClaimant();
      this.removeOrderLink();
      this.claimType = type;
    }

    removeOrderLink() {
      this.claimOrder = null;
    }

    setClaimOrder(event) {
      this.claimOrder = event.item;

      if (this.claimType === 'AUTO') {
        this.claimantAccountId = this.claimOrder.billing ? this.claimOrder.billing.accountId : null;
        this.claimantContacts = [];
        if (this.claimantAccountId ) {
          this.contacts.push(this.claimOrder.billing.accountContact);
        }
      }
    }

    onSubmit() {
        const claim = genMockClaim();

        claim.type = this.claimType;

        if (this.claimOrder) {
          claim.orderId = this.claimOrder._id;
          claim.vehicles = this.claimOrder.vehicles.map(v => v._id);
          claim.location.originId = this.claimOrder.originId;
          claim.location.destinationId = this.claimOrder.destinationId;
          claim.paidAmount = this.claimOrder?.carrier?.payment?.commission || 0;
        }

        claim.claimant.accountId = this.claimantAccountId;
        claim.claimant.contacts = this.claimantContacts;

        if (claim.claimant.accountId == '') {
          delete claim.claimant.accountId
        }

        this.store.dispatch(new fromStore.NewClaimFromList(claim));
        this.close();
    }

    close() {
      this.coreStore.dispatch(new CloseModal());
    }

    ngOnDestroy() {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
}