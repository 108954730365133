import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core'
import { FOLLOW_UP_RESOURCE, SocketService, User } from '@app/shared'
import {
  Expiration,
  ExpirationResourceEnum,
  ExpirationResourceTextEnum,
  ExpirationTypeTextEnum
} from '@app/shared/models/expiration.model'
import { Store } from '@ngrx/store'
import * as fromSidebarStore from '@app/sidebar/store';
import { AuthService } from '@app/users/services'
import { OpenModal } from '@app/modals/store/actions/layout.actions'
import { Observable, Subject } from 'rxjs'
import { tap, takeUntil, distinctUntilChanged } from 'rxjs/operators'
import moment from 'moment';
import * as fromCoreStore from '@app/core/store'

@Component({
    selector: 'app-expiration-list',
    templateUrl: './expiration-list.component.html',
    styleUrls: ['./expiration-list.component.scss'],
    standalone: false
})
export class ExpirationListComponent implements OnInit {
  filter = {
    isCompleted: false
  }
  isOpened: boolean = false
  filterTypes = [{key:'CARRIER',name:'CARRIER'},{key:'CUSTOMER',name:'CUSTOMER'}, {key:'DRIVER',name:'DRIVER'},{key:'TRAILER',name:'TRAILER'},{key:'TRUCK',name:'TRUCK'}]
  daysList: Array<any> = [{key:30, name:30},{key:45, name:45}, {key:60, name:60}]
  dayFromFilter: any = 30
  @Output()
  changeFilter = new EventEmitter<any>()
  @Input()
  currentUser$: Observable<User>;
  currentUser: User;
  expList = []
  resource: ExpirationResourceEnum
  expirations$: Observable<any>
  _expirations: Expiration[]
  selectType: string = 'Select type'
  destroyed$ = new Subject<boolean>();
  ExpirationTypeTextEnum = ExpirationTypeTextEnum
  ExpirationResourceEnum = ExpirationResourceEnum
  ExpirationResourceTextEnum = ExpirationResourceTextEnum
  lockedItems

  constructor(private store: Store<fromSidebarStore.State>,
    private cdr: ChangeDetectorRef, private socketService: SocketService) {
    this.store.dispatch(
      new fromSidebarStore.GetExpirations({
        resource: FOLLOW_UP_RESOURCE.COMPANY,
        resourceId: AuthService.CURRENT_USER?.company?._id,
        withResource: true,
        withExpired: true,
        filterDays: 30,
      })
    );
  }

  ngOnInit(): void {
    this.store.select(fromSidebarStore.selectAllExpirationsWithExpired).pipe(
      takeUntil(this.destroyed$),
      tap(e => {
        this._expirations = this.expUnit(e).filter(n => n.createdAt !== '');
        this.cdr.detectChanges()
      })
    ).subscribe()

    this.currentUser$.pipe(
      takeUntil(this.destroyed$),
      distinctUntilChanged(),
      tap((user) => {
        this.currentUser = user
      })
    ).subscribe()
  }


  isOpen = () => {
    this.isOpened = !this.isOpened
    if (this.isOpened === true) {
      this.dayFromFilter = '30'
      this.store.dispatch(new fromSidebarStore.SetExpirationFilter({ resource: undefined }))
    }
  }

  checkExpire = (date) => { return moment(date).diff(moment(), 'days') < 30 }

  filterByResource = (resource: ExpirationResourceEnum) => {
    this.resource = resource;
    this.store.dispatch(new fromSidebarStore.SetExpirationFilter({ resource }))
    this.store.dispatch(new fromSidebarStore.GetExpirations({
      resource,
      resourceId: AuthService.CURRENT_USER?.company?._id,
      withResource: true,
      withExpired: true,
      filterDays: this.dayFromFilter,
    }));
  }
  filterByDays = (event) => {
    this.dayFromFilter = event;
    this.store.dispatch(new fromSidebarStore.GetExpirations({
        resource: this.resource,
        resourceId: AuthService.CURRENT_USER?.company?._id,
        withResource: true,
        withExpired: true,
        filterDays: event,
      })
    );
  }

  expUnit = exp => {
    let expType
    exp.map(e => {
       
      expType = exp.find(expiration => expiration.resourceId === e.resourceId && expiration.type !== e.type);
      (expType?.type !== undefined)
        ?
        (
          e._typeUnion = [e.type, expType.type],
          e._dateUnion = [e.expiredDate, expType.expiredDate],
          e._expireDayArr = [e._expireDay, expType._expireDay]
        )
        :
        (
          e._typeUnion = [e.type,],
          e._dateUnion = [e.expiredDate],
          e._expireDayArr = [e._expireDay]
        )
    })
    return [...exp]
  }

  openResourceModal(resource: FOLLOW_UP_RESOURCE, resourceId: string, companyId) {
    switch (resource) {
      case FOLLOW_UP_RESOURCE.ACCOUNT_CONTACT:
        this.store.dispatch(
          new OpenModal({
            type: 'UPDATE_CONTACT',
            props: {
              size: 'md',
              windowClass: 'contact-modal',
              canChangeLoginChallenge: this.currentUser.permissions.contact_login_challenge,
              contactId: resourceId,
              selectResource: 'ACCOUNT_CONTACT_EXPIRATION'
            }
          })
        )
        break
      case FOLLOW_UP_RESOURCE.EQUIPMENT:
        this.store.dispatch(
          new OpenModal({
            type: 'EQUIPMENT_FORM',
            props: {
              _id: resourceId,
              carrierId: companyId,
              windowClass : "equipment-form-modal",
              selectResource: 'EQUIPMENT_FORM_EXPIRATION'
            }
          })
        )
        break

      default:
        break
    }
  }
  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
