import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Toast } from '@app/core/models/toast.model';

@Component({
    selector: 'app-toast-item',
    template: `
  <div class="alert alert-fixed alert-{{ type }} alert-dismissible fade show" role="alert">
    <button type="button" class="close" style="content" aria-label="Close" (click)="remove.emit(toast)">
      <i class="fa fa-times"></i>
    </button>
    <span>{{ msg }}</span>
  </div>
  `,
    styles: [],
    standalone: false
})
export class ToastItemComponent {
  @Input() toast: Toast;
  @Output() remove = new EventEmitter<Toast>();

  get id() {
    return this.toast.id;
  }

  get type() {
    return this.toast.type;
  }

  get msg() {
    return this.toast.msg;
  }
}
