import { Component, Input } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromStore from '@app/core/store'
import { CloseModal } from '@app/modals/store/actions/layout.actions'

@Component({
    selector: 'app-new-version-modal',
    templateUrl: './new-version-modal.component.html',
    standalone: false
})
export class NewVersionModalComponent {
  @Input() props

  constructor(private store: Store<fromStore.State>) { }
  pageReload(notes = false) {
    if (notes) {
      window.location.href = '/#/release-notes'
    }
    window.location.reload()
  }

  closeModal() {
    this.store.dispatch(new CloseModal())
  }
}
