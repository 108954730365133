{
  "name": "app",
  "version": "2.2.14",
  "license": "MIT",
  "scripts": {
    "ng": "ng",
    "start": "node --max_old_space_size=6000 ./node_modules/@angular/cli/bin/ng serve --host=0.0.0.0 --disable-host-check --port 4200",
    "start-prod": "ng serve --aot",
    "prod": "ng serve --configuration=prod",
    "deploy-dev": "ng build --configuration=dev && firebase deploy --only hosting:dev-app",
    "deploy-prod": "ng build --configuration=prod && firebase deploy --only hosting:app && node build-script.js",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "build-stats": "ng build --configuration=prod --output-hashing=none --stats-json",
    "stats": "webpack-bundle-analyzer dist/app/stats.json",
    "stats-exp": "ng build && source-map-explorer dist/app/**/*.js",
    "e2e": "ng e2e",
    "gitpd": "git push origin development",
    "build-8G": " node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --configuration=prod "
  },
  "private": true,
  "dependencies": {
    "@angular-devkit/core": "^19.2.1",
    "@angular/animations": "19.2.0",
    "@angular/common": "19.2.0",
    "@angular/compiler": "19.2.0",
    "@angular/core": "19.2.0",
    "@angular/forms": "19.2.0",
    "@angular/google-maps": "18.2.13",
    "@angular/localize": "19.2.0",
    "@angular/platform-browser": "19.2.0",
    "@angular/platform-browser-dynamic": "19.2.0",
    "@angular/router": "19.2.0",
    "@fullcalendar/angular": "6.1.16",
    "@fullcalendar/core": "6.1.15",
    "@fullcalendar/daygrid": "6.1.15",
    "@googlemaps/js-api-loader": "1.16.8",
    "@maskito/angular": "3.3.0",
    "@maskito/core": "3.3.0",
    "@ngrx/core": "1.2.0",
    "@ngrx/effects": "19.0.1",
    "@ngrx/entity": "19.0.1",
    "@ngrx/router-store": "19.0.1",
    "@ngrx/store": "19.0.1",
    "@ngrx/store-devtools": "19.0.1",
    "@ngx-progressbar/core": "5.3.2",
    "@popperjs/core": "2.11.8",
    "@sentry/angular": "9.10.1",
    "@sentry/angular-ivy": "7.120.3",
    "@sentry/browser": "9.10.1",
    "@sentry/tracing": "7.120.3",
    "@stripe/stripe-js": "5.8.0",
    "@swimlane/dragula": "3.8.0",
    "@swimlane/ngx-datatable": "20.1.0",
    "@textcomplete/core": "0.1.13",
    "@textcomplete/textarea": "0.1.13",
    "@types/google.maps": "3.58.1",
    "@types/socket.io-client": "3.0.0",
    "@types/swiper": "6.0.0",
    "angularx-qrcode": "16.0.2",
    "autoprefixer": "10.4.20",
    "big.js": "6.2.2",
    "chart.js": "4.4.8",
    "core-js": "3.41.0",
    "dropzone": "6.0.0-beta.2",
    "file-saver": "2.0.5",
    "flatpickr": "4.6.13",
    "flowbite": "2.2.1",
    "jquery": "3.7.1",
    "jszip": "^3.10.1",
    "moment": "2.30.1",
    "moment-timezone": "0.5.45",
    "ng-recaptcha": "13.2.1",
    "ngx-chips": "3.0.0",
    "ngx-cookie-service": "19.1.2",
    "ngx-infinite-scroll": "19.0.0",
    "ngx-papaparse": "8.0.0",
    "ngx-popperjs": "15.0.4",
    "nxt-sortablejs": "19.0.4",
    "openseadragon": "5.0.1",
    "pkg-dir": "8.0.0",
    "rxjs": "7.8.2",
    "shortcut-buttons-flatpickr": "0.3.1",
    "signature_pad": "5.0.4",
    "slim-select": "2.10.0",
    "socket.io": "4.8.1",
    "socket.io-client": "4.8.1",
    "sortablejs": "1.15.6",
    "swiper": "11.2.5",
    "ts-helpers": "1.1.2",
    "tslib": "2.8.1",
    "uuid": "9.0.1",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.2.0",
    "@angular/cli": "19.2.0",
    "@angular/compiler-cli": "19.2.0",
    "@fullhuman/postcss-purgecss": "5.0.0",
    "@tailwindcss/forms": "0.5.7",
    "@types/jasmine": "4.3.6",
    "@types/marked": "5.0.2",
    "@types/node": "20.11.5",
    "@types/sortablejs": "1.15.8",
    "axios": "1.5.1",
    "codelyzer": "6.0.2",
    "jasmine-core": "4.5.0",
    "jasmine-marbles": "0.9.2",
    "jasmine-spec-reporter": "7.0.0",
    "karma": "6.4.1",
    "karma-chrome-launcher": "3.1.1",
    "karma-cli": "2.0.0",
    "karma-coverage-istanbul-reporter": "3.0.3",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "2.0.0",
    "postcss": "8.4.33",
    "postcss-cli": "11.0.0",
    "postcss-loader": "7.3.4",
    "protractor": "7.0.0",
    "sass": "1.69.5",
    "source-map-explorer": "2.5.3",
    "tailwindcss": "3.3.2",
    "ts-node": "10.9.1",
    "tslint": "6.1.3",
    "typescript": "5.8.2",
    "webpack-bundle-analyzer": "4.8.0"
  }
}
