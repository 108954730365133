import { animate, state, style, transition, trigger } from '@angular/animations'
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectorRef } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { combineLatest, Observable, of, Subject } from 'rxjs'

import * as fromCoreStore from '@app/core/store'
import * as fromSharedStoreAction from '@app/shared/store/actions'
import * as fromUserStore from '@app/users/store'
// import * as fromOrderStore from '@app/quotes/store'

import * as fromModalStoreActions from '@app/core/store/actions'
import * as fromModalStoreSelectors from '@app/core/store/selectors'

import { Location } from '@angular/common'
import { NavigationStart, Router } from '@angular/router'
import { GetNewVersionNotification } from '@app/core/store'
import { User } from '@app/shared/models'
import { DieselService, ScheduleService } from '@app/shared/services'
import { Store } from '@ngrx/store'
import { catchError, distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators'
import { PushNotifications } from '../services/push-notifications.service'
import { NgxPopperjsTriggers, NgxPopperjsPlacements } from 'ngx-popperjs'
declare var require: any

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NavigationComponent implements OnInit, OnDestroy {

  NgxPopperjsTriggers = NgxPopperjsTriggers
  NgxPopperjsPlacements = NgxPopperjsPlacements


  menuState = 'in'
  @Output()
  onActiveMenu = new EventEmitter<boolean>()
  @Output()
  onNavMobileClose = new EventEmitter<void>()

  @Input()
  options: { isActiveSidebar?: boolean, mobile?: boolean } = { isActiveSidebar: false, mobile: false }

  darkThemeEnabled: boolean = false;

  openNavigation = false

  currentUser$: Observable<User>
  userSettings$: Observable<any>
  loggedIn$: Observable<boolean>
  destroyed$ = new Subject<boolean>()
  isLoadedAccounts$: Observable<boolean>
  statusSidebar = false
  @Input()
  isSubscribed = false
  onCustomerPortal = false
  widthOutput = null;
  locationEvent$
  contact
  roles
  currentRoute: string
  schedulesCount = 0
  savedTheme = localStorage.getItem('theme');

  fullView: boolean = false
  constructor(
    public dieselService: DieselService,
    private _sanitizer: DomSanitizer,
    private _PushNotifications: PushNotifications,
    private store: Store,
    private router: Router,
    private location: Location,
    private scheduleService: ScheduleService,
    private cdr: ChangeDetectorRef
  ) {
    this.router.events.subscribe(
      eventItem => {
        if (eventItem instanceof NavigationStart) {
          this.currentRoute = this.location.path()
          const currentRouteItems = this.currentRoute.split('/').filter(t => !!t).map(item => {
            return item?.indexOf('?') !== -1 ? item?.substring(0, item?.indexOf('?')) : item
          })
          const eventItemItems = eventItem.url.split('/').filter(t => !!t).map(item => {
            return item?.indexOf('?') !== -1 ? item?.substring(0, item?.indexOf('?')) : item
          })
          if (eventItemItems.length && currentRouteItems.length && eventItemItems?.[0] !== currentRouteItems?.[0]) {
            this.store.dispatch(new fromSharedStoreAction.CleanStore())
          }
        }
      }
    )
  }

  appVersion = require('../../../../package.json').version
  allDispatcher = false
  isLoggedIn = false
  userTheme = localStorage.getItem('color-theme')
  scheduleFilters = null

  ngOnInit() {

    this.store.select(fromCoreStore.selectPetPendingCount).pipe(
      takeUntil(this.destroyed$),
      map(count => {
        this.schedulesCount = count
        this.cdr.detectChanges()
      })
    ).subscribe()

    combineLatest([
      this.store.select(fromCoreStore.selectScheduleFilter),
      this.store.select(fromUserStore.getLoggedIn),
      this.store.select(fromUserStore.getUser)
    ])
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(([filters, loggedIn, user]) => {

        this.allDispatcher = !!filters?.allDispatcher || false

        this.isLoggedIn = loggedIn
        let f = {
          ...filters,
        }

        if (f?.rangeType) delete f.rangeType
        if (f?.driver) {
          f['driverId'] = f.driver._id
          delete f.driver
        }
        if (f?.unit) {
          f['unitId'] = f.unit._id
          delete f.unit
        }
        if (f?.dispatcher) {
          f['dispatchId'] = f.dispatcher._id
          delete f.dispatcher
        }
        if (user?.type == 'DISPATCH' && !this.allDispatcher) {
          f['dispatchId'] = user._id
        }
        delete f?.allDispatcher
        delete f?.onlySetStorage

        if (JSON.stringify(this.scheduleFilters) !== JSON.stringify(f)) {
          this.scheduleFilters = f
          if (loggedIn) {
            this.store.dispatch(new fromCoreStore.GetTabs(f))
          }
        }
      })

    this.currentUser$ = this.store.select(fromUserStore.getUser)
    this.userSettings$ = this.store.select(fromUserStore.getUserSettings)
    this.isLoadedAccounts$ = this.store.select(fromCoreStore.getContactLoaded)
    this.isLoadedAccounts$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe()
    this.store.dispatch(new GetNewVersionNotification({ version: this.appVersion }))

    this.store.select(fromModalStoreSelectors.selectPermissionRoleEntities).pipe(
      takeUntil(this.destroyed$),
      map(r => {
        this.roles = r

        if (this.isLoggedIn) {
          !Object.keys(r)?.length && this.store.dispatch(new fromModalStoreActions.GetPermissionRoles())
        }
        this.cdr.detectChanges()
      })
    ).subscribe()

    window.addEventListener("resize", this.resizeListener)
  }

  resizeDropdown() {
    this.resizeListener()
  }

  navToggle() {
    this.openNavigation = !this.openNavigation
  }

  onCloseNavigation(page?) {
    if (page == 'schedules') {
      this.store.dispatch(new fromCoreStore.GetTabs(this.scheduleFilters))
    }
    this.openNavigation = false
    this.onNavMobileClose.emit()
  }

  onToggleTheme() {
    this.darkThemeEnabled = !this.darkThemeEnabled
  }

  resizeListener() {
    this.widthOutput = document.querySelector(".dropdown-menu")
    if (this.widthOutput) {
      if (window.innerWidth < 1185) {
        this.widthOutput.style['transform'] = `translateX(${+window.innerWidth - 145}px)`
      } else {
        this.widthOutput.style['transform'] = ''
      }
    }
  }
  autocompleListFormatter = (data: any): SafeHtml => {
    const html = `<span>${data.toponymName}, ${data.adminCode1} </span>`
    return this._sanitizer.bypassSecurityTrustHtml(html)
  }

  async togglePushNotifications() {
    this._PushNotifications.toggleSubscription({ isPushEnabled: this.isSubscribed })
    this.isSubscribed = !this.isSubscribed

    // this.store.dispatch(new fromUserStore.TogglePushNotify());
  }

  toggleMenu() {
    // 1-line if statement that toggles the value:
    this.menuState = this.menuState === 'out' ? 'in' : 'out'
    this.dieselService.menuState = this.menuState
    console.log('TOGGLE MENU')
  }

  logout() {
    window.removeEventListener("resize", this.resizeListener)
    this.widthOutput = null
    this.store.dispatch(new fromUserStore.Logout())
  }

  toggleTheme() {
    if (document.documentElement.classList.contains("dark")) {
      this.userTheme = 'light'
      localStorage.setItem('color-theme', 'light')
      document.documentElement.classList.remove("dark")
    } else {
      this.userTheme = 'dark'
      localStorage.setItem('color-theme', 'dark')
      document.documentElement.classList.add("dark")
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
